@import '../theme-bootstrap';

$mpp-filter-prefix: mpp-filter-set;

@mixin filter-cross-line($rotate, $height: 21px, $width: 2px) {
  position: absolute;
  content: '';
  height: $height;
  width: $width;
  background-color: $color-cr18-black;
  transform: rotate($rotate);
}

.page-wrapper,
header {
  position: relative;
  &.fixed {
    position: fixed;
  }
}
.#{$mpp-filter-prefix}__interactive {
  .#{$mpp-filter-prefix} {
    &__line {
      display: block;
      float: left;
      font-size: 18px;
      height: 15px;
      padding: 10px 15px;
      line-height: 1;
      @include breakpoint($medium-up) {
        padding: 0 10px 0 0;
        font-size: 15px;
      }
    }
    &__item {
      display: flex;
      padding-right: 15px;
      @include breakpoint($medium-up) {
        display: inline-block;
        padding-right: 0;
      }
    }
    &__filters {
      min-width: 200px;
      @include breakpoint($medium-up) {
        top: 1em;
      }
    }
    &__title {
      font-family: $font--futura-demi;
      border-bottom: 1px solid $color-black;
      @include breakpoint($medium-up) {
        max-width: 170px;
      }
      &--text {
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        color: $color-green;
        text-align: center;
        text-transform: capitalize;
        @include breakpoint($medium-up) {
          max-width: 250px;
          text-align: left;
          font-size: 15px;
          font-family: $font--futura-medium;
        }
      }
      &--accordion {
        display: none;
      }
    }
    &__arrow {
      padding-bottom: 5px;
      display: none;
      @include breakpoint($medium-up) {
        display: table-cell;
      }
    }
    &__section {
      padding-bottom: 0;
      @include breakpoint($medium-up) {
        margin: 0 15px 0 0;
      }
    }
    &__header {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
    &__reset {
      position: absolute;
      @include breakpoint($medium-up) {
        padding: 0 15px;
      }
    }
    &__selections-reset {
      left: 0;
    }
    &__mobile-cta {
      display: flex;
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
}
.#{$mpp-filter-prefix} {
  &__container {
    display: none;
    @include breakpoint($medium-up) {
      background: $color-white;
      border: 0;
      display: block !important;
      height: auto;
      overflow: visible;
      padding: 0;
      position: static;
      width: auto;
      z-index: 1;
    }
    @include breakpoint($medium-portrait-only) {
      padding-left: 10px;
    }
    @include breakpoint($medium-landscape-only) {
      padding-left: 10px;
    }
  }
  &__body {
    @include breakpoint($medium-up) {
      padding: 0;
      display: inline-block;
    }
  }
  &__title {
    line-height: 2;
    text-transform: uppercase;
    display: table;
    width: 100%;
    @include breakpoint($medium-up) {
      border: none;
      cursor: pointer;
      font-size: 15px;
      height: 15px;
      line-height: 1;
      position: relative;
      vertical-align: middle;
      text-transform: capitalize;
    }
    &--text {
      display: none;
    }
  }
  &__header {
    @include pie-clearfix;
    display: none;
    @include breakpoint($medium-up) {
      display: inline-block;
      font-size: 15px;
      height: 15px;
      line-height: 1;
      padding: 0;
    }
  }
  &__footer {
    @include pie-clearfix;
    display: none;
    background: $color-white;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    padding: 15px;
    margin-bottom: 40px;
    border-top: 1px solid $color-light-gray;
    &--reset {
      border: 1px solid $color-light-gray;
      padding: 0;
      background-color: transparent;
      color: $color-drak-black;
      font-size: 18px;
      text-transform: capitalize;
      width: 49%;
    }
    &--reset:hover {
      text-decoration: none;
    }
  }
  &__section {
    font-size: 15px;
    @include breakpoint($medium-up) {
      display: inline-block;
      padding: 0;
      margin: 0 1em;
      position: relative;
    }
    &--2col .#{$mpp-filter-prefix}__filters {
      columns: 2 auto;
      @include breakpoint($medium-up) {
        columns: 1;
      }
    }
  }
  &__item {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  &__label {
    color: $color-black;
    cursor: pointer;
    font-family: $font--futura-demi;
    font-size: 15px;
    float: left;
    line-height: 2;
    padding-left: 15px;
    text-transform: uppercase;
    width: 100%;
    @include breakpoint($medium-up) {
      border: none;
      display: inline-block;
      cursor: auto;
      font-family: $font--futura-book;
      line-height: 1;
      padding: 0;
      text-transform: capitalize;
    }
  }
  &__reset {
    margin-bottom: 10px;
    display: none;
    padding: 10px 15px;
    @include breakpoint($medium-up) {
      padding: 0;
    }
    .#{$mpp-filter-prefix}--filtered & {
      display: inline-block;
    }
  }
  &__close,
  &__line {
    display: none;
  }
  &__submit {
    background: $color-drak-black;
    padding: 0;
    color: $color-white;
    font-size: 18px;
    text-transform: capitalize;
    float: right;
    width: 49%;
  }
  &__mobile-cta {
    display: flex;
    cursor: pointer;
    position: relative;
    font-size: 15px;
    margin-left: 10px;
    @include breakpoint($medium-up) {
      display: none;
    }
    &-text {
      min-width: 130px;
      border: 1px solid $color-light-gray;
      padding: 10px 30px 10px 15px;
      &--edit {
        display: none;
      }
    }
    &-count {
      display: none;
      .mpp-filter-set--filtered & {
        display: inline-block;
        color: $color-gray;
      }
    }
    &--icon {
      border: 1px solid $color-light-gray;
      padding: 10px 30px 10px 15px;
      border-left: none;
      &:before {
        @include filter-cross-line(-90deg, 18px, 1px);
        margin: 0 8px;
      }
      &:after {
        @include filter-cross-line(-180deg, 18px, 1px);
        margin: 0 8px;
      }
    }
  }
  &__button {
    $button: &;
    display: flex;
    width: fit-content;
    cursor: pointer;
    padding: 10px 0;
    font-family: $font--futura-book;
    @include breakpoint($medium-up) {
      border: 0;
      white-space: nowrap;
      padding: 5px 0;
      &:hover {
        color: $color-gray;
        opacity: 1;
      }
    }
    &--checkbox {
      background: $color-white;
      border: $color-black solid 1px;
      display: inline-block;
      margin-right: 8px;
      height: 18px;
      width: 18px;
      @include breakpoint($medium-up) {
        height: 12px;
        width: 12px;
      }
    }
    &--text {
      line-height: 1.2;
      @include breakpoint($medium-up) {
        line-height: 1;
      }
    }
    &.active {
      #{$button}--checkbox {
        &:after {
          content: '';
          position: absolute;
          border-top: 1px solid $color-drak-black;
          width: 22px;
          transform: rotate(135deg);
          transform-origin: 0 0;
          left: 32px;
          @include breakpoint($medium-up) {
            width: 15px;
            left: 27px;
          }
        }
      }
    }
  }
  &__arrow {
    display: table-cell;
    height: 15px;
    line-height: 1;
    width: 25px;
    vertical-align: middle;
    &--mobile {
      display: table-cell;
    }
    i {
      height: 13px;
      width: 13px;
      position: relative;
      bottom: -1px;
      transition: 0.4s ease;
      display: block;
      float: right;
      &::before,
      &::after {
        position: absolute;
        content: '';
        transition: 0.4s ease;
        background-color: transparent;
        width: 1px;
        height: 16px;
        border-bottom: 8px solid $color-black;
        top: 2px;
        left: 0;
      }
      &::before {
        transform: rotate(-135deg);
      }
      &::after {
        position: absolute;
        transform: rotate(135deg);
      }
      .filter-active & {
        transform: translate(0, -6px);
        &::before {
          transform: rotate(-45deg);
        }
        &::after {
          transform: rotate(45deg);
        }
      }
    }
  }
  &__filters {
    display: block;
    @include breakpoint($medium-up) {
      background: $color-white;
      border: solid 1px $color-gray;
      position: absolute;
      padding: 1em;
      min-width: 110px;
      top: 1.5em;
      z-index: 11;
    }
    &.active {
      display: inline-block;
    }
    &.hide-filter {
      display: none;
    }
  }
  &__overlay {
    display: block;
    background: $color-white;
    font-family: $font--futura-book;
    height: calc(100% - 70px);
    left: 0;
    position: fixed;
    right: 0;
    text-align: left;
    top: 31px;
    padding-bottom: 80px;
    width: auto;
    z-index: 137;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    @include breakpoint($medium-up) {
      display: none;
    }
    .#{$mpp-filter-prefix} {
      &__title {
        border-bottom: none;
        &:not(.filter-active) {
          .#{$mpp-filter-prefix}__title--accordion::after {
            @include filter-cross-line(-180deg);
            right: 24px;
            margin-top: 6px;
          }
        }
        &--accordion {
          display: block;
          padding: 10px 15px;
          position: relative;
          font-family: $font--futura-demi;
          font-size: 18px;
          &::before {
            @include filter-cross-line(90deg);
            right: 24px;
            margin-top: 6px;
          }
        }
        &--text {
          display: none;
        }
      }
      &__line,
      &__selections-reset,
      &__arrow {
        display: none;
      }
      &__item {
        width: 100%;
        display: block;
        padding-right: 0;
      }
      &__section {
        border-bottom: 1px solid $color-light-gray;
        padding-bottom: 0;
      }
      &__header {
        display: block;
        border-bottom: 1px solid $color-light-gray;
      }
      &__footer {
        display: block;
      }
      &__filters {
        padding: 0 15px 15px;
      }
      &__close {
        float: right;
        width: 24px;
        height: 24px;
        margin-top: 10px;
        &:before {
          @include filter-cross-line(-45deg);
          right: 24px;
        }
        &:after {
          @include filter-cross-line(-135deg);
          right: 24px;
        }
      }
      &__count {
        padding: 10px 15px;
        font-family: $font--futura-demi;
        line-height: 2;
        text-transform: uppercase;
        @include breakpoint($medium-up) {
          opacity: 1;
          float: none;
        }
        &--text {
          display: inline-block;
          color: $color-gray;
        }
      }
    }
  }
  &__selections {
    display: none;
    font-size: 15px;
    text-align: center;
    padding: 5px 30px 10px 30px;
    clear: both;
    &-reset {
      color: $color-gray;
      text-decoration: none;
      white-space: nowrap;
      position: relative;
      font-family: $font--futura-book;
      &:hover {
        text-decoration: none;
      }
      &--desktop {
        text-decoration: none;
        line-height: 1;
        opacity: 0;
        transition: opacity 0.2s;
        .mpp-filter-set--filtered & {
          opacity: 1;
        }
        .icon--close {
          font-size: 10px;
          padding: 0 5px 5px;
          font-family: $font--futura-medium;
        }
      }
    }
    &-tags {
      text-transform: uppercase;
    }
    &-count {
      padding: 5px 0 0 0;
    }
  }
}

// no results found
.filter-no-matches {
  display: none;
  text-align: center;
  padding: 60px 0;
  &__cta {
    padding: 10px;
  }
}
